import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import MainMenu from './MainMenu'
import Logo from '../pages/logo.svg'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { axiosKazlingoJson } from '../utils/axiosKazlingo'

const { Header, Content, Sider } = Layout

const Wrapper = styled.div`
  margin: 24px;
  height: 100vh;
`
const LogoContainer = styled.div`
  box-sizing: border-box;
  background: #fff;
  padding: 24px 0px 16px 24px;
`

const StyledSider = styled(Sider)`
  height: 100%;
`

const StyledLayout = styled(Layout)`
  background-color: #fff;
`

const StyledHeader = styled(Header)`
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
`
const StyledText = styled.div`
  height: 28px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
`

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      name
      phoneNumber
      emailAddress
    }
  }
`

const withMainLayout = Page => {
  return props => {
    const history = useHistory()
    const currentUrl = history.location.pathname
    const { moduleId, sectionId, buttonId } = useParams()
    const [moduleOrder, setModuleOrder] = useState()
    const [sectionOrder, setSectionOrder] = useState()
    const [buttonOrder, setButtonOrder] = useState()
    const [currentHeader, setCurrentHeader] = React.useState('')
    const [dynamicText, setDynamicText] = useState('')
    const [getUser, { loading: loadingUser }] = useLazyQuery(GET_USER, {
      variables: { id: props.match.params.userId },
      onError: e => console.log('GET_USER', e),
      onCompleted: data => {
        const { name, phoneNumber, emailAddress } = data.user
        const string = name + ' ' + (phoneNumber || emailAddress)
        setDynamicText(string)
      }
    })
    useEffect(() => {
      if (props.path.includes('userLevel')) {
        getUser()
      }
    }, [])

    const adminMenu = useMemo(
      () => [
        { link: '/users', name: 'Пользователи' },
        { link: '/notifications', name: 'Уведомления' },
        { link: '/stories', name: 'Истории-новости' },
        { link: '/creators', name: 'Авторы/Издатели' },
        { link: '/publishers', name: 'Издатели' },
        { link: '/songs', name: 'Песни' },
        { link: '/playlists', name: 'Плейлисты' },
        { link: '/playlistsCollections', name: 'Коллекции плейлистов' },
        { link: '/instruments', name: 'Инструменты' },
        { link: '/genres', name: 'Жанры' },
        { link: '/categories', name: 'Категории' },
        { link: '/tags', name: 'Тэги' },
        { link: '/tales', name: 'Сказки' },
        { link: '/collections', name: 'Сборники' },
        { link: '/cartoons', name: 'Мультфильмы' },
        { link: '/education', name: 'Обучение обложки' },
        { link: '/letters', name: 'Алфавит' },
        { link: '/topics', name: 'Темы' },
        { link: '/practiceLetters', name: 'Буквы практики' },
        { link: '/practiceNumbers', name: 'Цифры практики' },
        { link: '/practiceColors', name: 'Цвета практики' },
        { link: '/practiceFigures', name: 'Фигуры практики' },
        { link: '/practiceItemsTwoColor', name: 'Два цвета практики' },
        { link: '/digits', name: 'Цифры' },
        { link: '/colors', name: 'Цвета' },
        { link: '/animals', name: 'Животные' },
        { link: '/kitchen', name: 'Предметы на кухне' },
        { link: '/qaraSozder', name: 'Слова назидания Абая' },
        { link: '/dashboard', name: 'Dashboard' },
        { link: '/educationCollections', name: 'Коллекции обучения' },
        { link: '/courses', name: 'Курсы обучения' },
        { link: '/lessonTypeAudios', name: 'Аудио типов урока' },
        { link: '/syllables', name: 'Слога' },
        { link: '/words', name: 'Слова' },
        { link: '/banners', name: 'Баннеры' },
        {
          link: '/userLevel',
          name: `Уровень пользователя ${dynamicText} в обучении`
        },
        {
          link: '/traditionsArticles',
          name: 'Статьи традиций'
        },
        {
          link: '/traditions',
          name: 'Традиции'
        },
        {
          link: '/traditionsCollections',
          name: 'Коллекции традиций'
        },
        {
          link: '/traditionsArticlesImages',
          name: 'Изображения статей традиций'
        },
        { link: '/transactions', name: 'Транзакции' },
        { link: '/usersTransactions', name: 'Транзакции пользователей' },
        { link: '/appDetails', name: 'Детали приложения пользователей' },
        { link: '/riddles', name: 'Загадки' },
        { link: '/riddlesCollections', name: 'Коллекции загадок' },
        { link: '/zhanyltpashtar', name: 'Скороговорки' },
        { link: '/backgroundImages', name: 'Фоновые изображения' },
        { link: '/proverbs', name: 'Пословицы и поговорки' },
        {
          link: '/proverbsCollections',
          name: 'Коллекции Пословиц и поговорок'
        },
        { link: '/kazlingo-admin/assessments', name: 'Казлинго задачи' },
        { link: '/kazlingo-admin/modules', name: 'Казлинго модули' },
        {
          link: `/kazlingo-admin/modules/${moduleId}/sections`,
          name: `${moduleOrder + 1} модуль`
        },
        {
          link: `/kazlingo-admin/modules/${moduleId}/sections/${sectionId}/buttons`,
          name: `${moduleOrder + 1} модуль -> ${sectionOrder + 1} раздел`
        },
        {
          link: `/kazlingo-admin/modules/${moduleId}/sections/${sectionId}/buttons/${buttonId}/assessments`,
          name: `${moduleOrder + 1} модуль -> ${sectionOrder +
            1} раздел -> ${buttonOrder + 1} кнопка`
        },
        {
          link: `/kazlingo-admin/commonWords`,
          name: 'Казлинго сборник слов'
        }
      ],
      [moduleOrder, sectionOrder, buttonOrder]
    )

    // const contentManagerMenu = [
    //   { link: '/contentAudios', name: 'Аудио контенты' },
    //   { link: '/authors', name: 'Авторы' },
    //   { link: '/publishers', name: 'Издатели' },
    //   { link: '/voiceActors', name: 'Дикторы' },
    //   { link: '/actors', name: 'Актеры' }
    // ]

    React.useEffect(() => {
      if (buttonId || sectionId || !moduleId) return
      const fetchModuleOrder = async () => {
        const response = await axiosKazlingoJson.get(
          `/modules/${moduleId}/order`
        )
        setModuleOrder(response.data.moduleOrder)
      }
      fetchModuleOrder()
    }, [moduleId])
    React.useEffect(() => {
      if (buttonId || !sectionId) return
      const fetchSectionOrder = async () => {
        const response = await axiosKazlingoJson.get(
          `/modules/${moduleId}/sections/${sectionId}/order`
        )
        setModuleOrder(response.data.moduleOrder)
        setSectionOrder(response.data.sectionOrder)
      }
      fetchSectionOrder()
    }, [sectionId])
    React.useEffect(() => {
      if (!buttonId) return
      const fetchButtonOrder = async () => {
        const response = await axiosKazlingoJson.get(
          `/modules/${moduleId}/sections/${sectionId}/buttons/${buttonId}/order`
        )
        setModuleOrder(response.data.moduleOrder)
        setSectionOrder(response.data.sectionOrder)
        setButtonOrder(response.data.buttonOrder)
      }
      fetchButtonOrder()
    }, [buttonId])

    React.useEffect(() => {
      if (moduleId && moduleOrder == undefined) return
      adminMenu.forEach(item => {
        if (currentUrl.startsWith(item.link)) {
          setCurrentHeader(item.name)
        }
      })
    }, [currentUrl, dynamicText, moduleOrder, sectionOrder, buttonOrder])

    return (
      <StyledLayout>
        <StyledSider>
          <LogoContainer>
            <img src={Logo} alt="logo" />
          </LogoContainer>
          <MainMenu {...{ currentUrl, history, setCurrentHeader }} />
        </StyledSider>
        <Layout>
          <StyledHeader>
            <StyledText>{currentHeader}</StyledText>
          </StyledHeader>
          <Content>
            <Wrapper>
              <Page {...props} />
            </Wrapper>
          </Content>
        </Layout>
      </StyledLayout>
    )
  }
}

export default withMainLayout
