export const ASSESSMENT_T = Object.freeze({
  chooseCorrectPicture: 'chooseCorrectPicture',
  chooseCorrectTranslation: 'chooseCorrectTranslation',
  chooseCorrectAnswer: 'chooseCorrectAnswer',
  whatDidYouHear: 'whatDidYouHear',
  matchWords: 'matchWords',
  translateSentence: 'translateSentence',
  listenAudioCreateSentence: 'listenAudioCreateSentence',
  listenAudioFinishSentence: 'listenAudioFinishSentence',
  fillBlankSpace: 'fillBlankSpace',
  answerQuestion: 'answerQuestion',
  answerQuestionImage: 'answerQuestionImage',
  qonzhyqStories: 'qonzhyqStories'
})

export const ASSESSMENT_T_LC = Object.freeze({
  chooseCorrectPicture: 'Выбери правильный рисунок',
  chooseCorrectTranslation: 'Выбери правильный перевод',
  chooseCorrectAnswer: 'Выбери правильный вариант',
  whatDidYouHear: 'Что Вы услышали?',
  matchWords: 'Соедини слова',
  translateSentence: 'Переведи предложение',
  listenAudioCreateSentence: 'Послушай и собери предложение',
  listenAudioFinishSentence: 'Прослушай аудио и дополни предложение',
  fillBlankSpace: 'Заполни пробел',
  answerQuestion: 'Ответьте на вопрос',
  answerQuestionImage: 'Ответьте на вопрос по картинке',
  qonzhyqStories: 'Конжык хикаялары'
})
