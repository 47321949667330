import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../context/useLoading'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'
import { axiosKazlingoFormData } from '../../utils/axiosKazlingo'
import QShop from './Streaks'
import { errorLog } from '../../utils/errorLog'

const processData = (formData, values) => {
  console.log('values >>> ', values)

  const file = values.imageFile.originFileObj

  formData.append('icon', file, file.name)
  formData.append('category', values.category)
  formData.append('name', values.name)
}

const StreaksContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allGoods, setAllGoods] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchGoods = async () => {
      try {
        const { data } = await axiosKazlingoFormData.get(
          '/achievements/streaks'
        )
        setAllGoods(data.data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    fetchGoods()
  }, [])

  const addGoodsHandler = async (form, closeFunc) => {
    try {
      console.log('form', form)
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()

      processData(formData, values)

      const response = await axiosKazlingoFormData.post(
        '/q-shop/goods',
        formData
      )
      if (response.status == 201) {
        setAllGoods([...allGoods, response.data])
        toast.success('Успешно добавлено')
      }
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteGoodsHandler = async (_id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoFormData.delete(`/commonWords/details/${_id}`)
      setAllGoods(allGoods.filter(a => a._id !== _id))
      toast.success('Успешно удалено')
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке</div>
  }

  return (
    <QShop
      goods={allGoods}
      createHandler={addGoodsHandler}
      deleteHandler={deleteGoodsHandler}
    />
  )
}

export default WithMainLayout(StreaksContainer)
