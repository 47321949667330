import React, { useEffect } from 'react'
import { Button, Form, Input, Upload, Space, Row, Col, Checkbox } from 'antd'
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'

const GoodsForm = ({ form, initialValues, onSubmit }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const formData = {
        ...values,
        imageFile: values.imageFile?.file || values.imageFile
      }
      console.log('formData>>>', formData)
      onSubmit(formData)
    })
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="category"
        label="category"
        rules={[{ required: true, message: 'Please enter the goods category' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="name"
        label="name"
        rules={[{ required: true, message: 'Please enter the goods name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="imageFile"
        label="Image File"
        valuePropName="file"
        getValueFromEvent={e => e?.fileList?.[0] || null}
      >
        <Upload beforeUpload={() => false} maxCount={1} accept="image/*">
          <Button icon={<UploadOutlined />}>Иконка</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default GoodsForm
