import React from 'react'

export const qonzhyqStoriesTable = [
  {
    title: 'Название',
    dataIndex: 'correctText',
    width: '15%'
  },
  {
    title: 'Варианты',
    render: item => (
      <div>
        {item.storyQuestions.map(question => {
          console.log(question)
          return (
            <span>
              <strong>Вопрос:</strong> {question.correctText}
              {question.variants.map(questionVariant => (
                <div
                  style={{
                    marginBottom: '10px',
                    backgroundColor: questionVariant.isCorrect
                      ? 'rgba(173, 255, 47, 0.5)'
                      : 'rgba(255, 0, 0, 0.5)',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span>
                    <strong>Ответ:</strong> {questionVariant.text}
                  </span>
                </div>
              ))}
            </span>
          )
        })}
      </div>
    )
  }
]
