import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import { axiosKazlingoFormData } from '../../../utils/axiosKazlingo'
import Words from './Alphabet'
import { errorLog } from '../../../utils/errorLog'

const processData = (formData, values) => {
  Object.keys(values).forEach(key => {
    if (key !== 'variants') formData.append(key, values[key])
  })
  if (values.variants) {
    const variants = []
    values.variants.forEach(variant => {
      const { text, isCorrect, audioFile } = variant
      variants.push({ text, isCorrect })
      formData.append('variantsFileArray', audioFile || new Blob([]))
    })
    formData.set('variants', JSON.stringify(variants))
  }
}

const AlphabetContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allLetters, setAllLetters] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const { data } = await axiosKazlingoFormData.get('/alphabet')
        setAllLetters(data.data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    fetchWords()
  }, [])

  const addLetterHandler = async (form, closeFunc) => {
    try {
      console.log('form', form)
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()

      processData(formData, values)

      const response = await axiosKazlingoFormData.post('/alphabet', formData)
      if (response.status == 201) {
        setAllLetters([...allLetters, response.data])
        toast.success('Успешно добавлено')
      }
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const updateWordHandler = async (_id, form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      processData(formData, values)
      const response = await axiosKazlingoFormData.put(
        `/alphabet/${_id}`,
        formData
      )
      if (response.status == 201) {
        setAllLetters(allLetters.map(a => (a._id === _id ? response.data : a)))
        toast.success('Успешно обновлено')
      }
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteWordHandler = async (_id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoFormData.delete(`/alphabet/${_id}`)
      setAllLetters(allLetters.filter(a => a._id !== _id))
      toast.success('Успешно удалено')
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке</div>
  }

  return (
    <Words
      letters={allLetters}
      createHandler={addLetterHandler}
      updateHandler={updateWordHandler}
      deleteHandler={deleteWordHandler}
    />
  )
}

export default WithMainLayout(AlphabetContainer)
