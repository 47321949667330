import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLoading } from '../../../../context/useLoading'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import UpIcon from '../../../../assets/icons/Up.svg'
import SectionsForm from './SectionsForm'
import { axiosKazlingoJson } from '../../../../utils/axiosKazlingo'

export const handleTableChange = (pagination, filters, sorter, extra) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const Sections = ({
  sections,
  createHandler,
  updateHandler,
  deleteHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [sectionsItem, setSectionsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchItem, setSearchItem] = useState(null)
  const [listSections, setListSections] = useState(null)
  const { hideLoading, showLoading } = useLoading()
  const { moduleId } = useParams()
  const [assessmentsCount, setAssessmentsCount] = useState(null)
  useEffect(() => {
    if (searchItem) {
      const filteredSections = sections.filter(section => {
        return section.sectionName
          ?.toLowerCase()
          .includes(searchItem.toLowerCase())
      })
      setListSections(filteredSections)
    } else {
      setListSections(sections)
    }
  }, [sections, searchItem])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.isVisible ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        />
      )
    },
    { title: 'Название', dataIndex: 'sectionName' },
    { title: 'Порядок', dataIndex: 'sectionOrder' },
    {
      title: 'Баннер',
      render: section =>
        section?.sectionAssets?.sectionBannerImageUrl && (
          <Image
            shape="square"
            style={{ width: 128, height: 64 }}
            src={section.sectionAssets.sectionBannerImageUrl}
          />
        )
    },
    {
      title: 'Задний фон',
      render: section =>
        section?.sectionAssets?.sectionBackgroundImageUrl && (
          <Image
            shape="square"
            style={{ width: 128, height: 256 }}
            src={section.sectionAssets.sectionBackgroundImageUrl}
          />
        )
    },
    {
      title: 'Фото города',
      render: section =>
        section?.sectionAssets?.sectionCityImageUrl && (
          <Image
            shape="square"
            style={{ width: 128, height: 128 }}
            src={section.sectionAssets.sectionCityImageUrl}
          />
        )
    },
    {
      title: 'Заголовок города',
      dataIndex: 'sectionCityHeaderText'
    },
    {
      title: 'Описание города',
      dataIndex: 'sectionCityDescriptionText'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setSectionsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setSectionsItem(item)
            }}
          >
            Удалить
          </Button>
          <div>
            <Link to={'../../modules'}>
              <Button style={{ marginLeft: '5%' }} onClick={handleTableChange}>
                <Icon src={UpIcon} style={{ transform: 'rotate(-90deg)' }} />
              </Button>
            </Link>

            <Link to={`sections/${item._id}/buttons`}>
              <Button style={{ marginLeft: '5%' }} onClick={handleTableChange}>
                <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
              </Button>
            </Link>
          </div>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        onChange={handleTableChange}
        dataSource={listSections}
        columns={columns}
        rowKey={item => item._id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Текст"
              onChange={e => {
                setSearchItem(e.target.value.toLowerCase())
              }}
            />
            <div>
              <Button
                type="primary"
                onClick={async () => {
                  const response = await axiosKazlingoJson.get(
                    `/modules/${moduleId}/assessmentsCount`
                  )
                  setAssessmentsCount(response.data.count)
                }}
              >
                Количество ассессментов
              </Button>
              {assessmentsCount}
            </div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая секция
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая секция"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <SectionsForm form={form} />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
          }}
          onOk={() => {
            updateHandler(sectionsItem._id, formForEdit, setEditModal)
          }}
        >
          <SectionsForm form={formForEdit} initialValues={sectionsItem} />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(sectionsItem._id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {sectionsItem.sectionName} ?</h3>
        </Modal>
      )}
    </>
  )
}

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Sections
