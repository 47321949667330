import React, { useEffect } from 'react'
import { Form, Input, Button, Upload, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  ChooseCorrectPictureVariants,
  ChooseCorrectTranslationVariants,
  ChooseCorrectAnswerVariants,
  WhatDidYouHearVariants,
  MatchWordsVariants,
  TranslateSentenceVariants,
  ListenAudioCreateSentenceVariants,
  ListenAudioFinishSentenceVariants,
  FillBlankSpaceVariants,
  AnswerQuestionVariants,
  QonzhyqStoriesVariants
} from './forms'
import { ASSESSMENT_T, ASSESSMENT_T_LC } from './enum'

const AssessmentsForm = ({ form, initialValues }) => {
  const [stateType, setStateType] = React.useState(
    initialValues?.type || ASSESSMENT_T.chooseCorrectPicture
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const renderVariants = () => {
    switch (stateType) {
      case ASSESSMENT_T.chooseCorrectPicture:
        return <ChooseCorrectPictureVariants form={form} />
      case ASSESSMENT_T.chooseCorrectTranslation:
        return <ChooseCorrectTranslationVariants /> // same2 (one text)
      case ASSESSMENT_T.chooseCorrectAnswer:
        return <ChooseCorrectAnswerVariants /> // same2 (one text)
      case ASSESSMENT_T.whatDidYouHear:
        return <WhatDidYouHearVariants /> // same2 (one text)
      case ASSESSMENT_T.matchWords:
        return <MatchWordsVariants form={form} />
      case ASSESSMENT_T.translateSentence:
        return <TranslateSentenceVariants form={form} /> // same1 (array of text)
      case ASSESSMENT_T.listenAudioCreateSentence:
        return <ListenAudioCreateSentenceVariants form={form} /> // same1 (array of text)
      case ASSESSMENT_T.listenAudioFinishSentence:
        return <ListenAudioFinishSentenceVariants /> // same1 (array of text)
      case ASSESSMENT_T.fillBlankSpace:
        return <FillBlankSpaceVariants /> // same1 (array of text)
      case ASSESSMENT_T.answerQuestion:
        return <AnswerQuestionVariants />
      case ASSESSMENT_T.answerQuestionImage:
        return <AnswerQuestionVariants />
      case ASSESSMENT_T.qonzhyqStories:
        return <QonzhyqStoriesVariants />
      default:
        return null
    }
  }

  const renderFormItem = () => {
    switch (stateType) {
      case ASSESSMENT_T.chooseCorrectPicture:
      case ASSESSMENT_T.whatDidYouHear:
      case ASSESSMENT_T.listenAudioCreateSentence:
      case ASSESSMENT_T.listenAudioFinishSentence:
      case ASSESSMENT_T.chooseCorrectTranslation:
        return (
          <>
            <Form.Item
              name="audioFile"
              label="Аудио файл"
              valuePropName="file"
              getValueFromEvent={e => e.file}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Выберите аудио файл</Button>
              </Upload>
            </Form.Item>
          </>
        )
      case ASSESSMENT_T.answerQuestionImage:
        return (
          <>
            <Form.Item
              name="imageFile"
              label="Фото файл"
              valuePropName="file"
              getValueFromEvent={e => e.file}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Выберите фото файл</Button>
              </Upload>
            </Form.Item>
          </>
        )
      case ASSESSMENT_T.fillBlankSpace:
        return (
          <Form.Item name="translateText" label="Перевод">
            <Input />
          </Form.Item>
        )
      case ASSESSMENT_T.chooseCorrectAnswer:
      case ASSESSMENT_T.answerQuestion:
      case ASSESSMENT_T.qonzhyqStories:
        return null // doesnt have any unusual
      case ASSESSMENT_T.matchWords:
        return (
          <Form.Item
            name="typeText"
            label="typeText (она будет появляться в заголовке как вопрос)"
            initialValue={'Соотнесите слова'}
            rules={[{ required: true, message: 'Пожалуйста, введите задачу' }]}
          >
            <Input />
          </Form.Item>
        )
      default:
        return null
    }
  }

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        key="type"
        label="Тип"
        name="type"
        rules={[{ required: true, message: 'Пожалуйста, выберите тип' }]}
        initialValue={stateType}
      >
        <Select
          placeholder="Выберите тип"
          mode="single"
          onSelect={value => {
            setStateType(value)
          }}
        >
          {Object.keys(ASSESSMENT_T).map(field => (
            <Select.Option
              key={ASSESSMENT_T[field]}
              value={ASSESSMENT_T[field]}
            >
              {ASSESSMENT_T_LC[field]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="correctText" label="Название (слово, фраза или т.п.)">
        <Input />
      </Form.Item>
      {renderFormItem()}
      {renderVariants()}
    </Form>
  )
}

export default AssessmentsForm
