import React from 'react'
import { Form, Input, Button, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const QonzhyqStoriesVariants = () => (
  <Form.List name="storyQuestions" initialValue={[]}>
    {(questions, { add: addQuestion, remove: removeQuestion }) => (
      <>
        {questions.map(question => (
          <div key={question.key} style={{ marginBottom: 20 }}>
            <Form.Item
              name={[question.name, 'correctText']}
              label={`Вопрос ${question.name + 1}`}
              rules={[
                { required: true, message: 'Пожалуйста, введите текст вопроса' }
              ]}
            >
              <Input placeholder="Введите текст вопроса" />
            </Form.Item>
            <Form.List name={[question.name, 'variants']}>
              {(fields, { add: addOption, remove: removeOption }) => (
                <>
                  {fields.map(field => (
                    <div
                      key={field.key}
                      style={{
                        marginBottom: 16,
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Form.Item
                        name={[field.name, 'text']}
                        label={`Вопрос ${question.name +
                          1} Вариант ${field.name + 1}`}
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите текст варианта'
                          }
                        ]}
                      >
                        <Input placeholder="Введите текст варианта" />
                      </Form.Item>

                      <Form.Item
                        name={[field.name, 'isCorrect']}
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, выберите правильный вариант'
                          }
                        ]}
                      >
                        <Radio.Group defaultValue={false}>
                          <Radio value={true}>Правильный</Radio>
                          <Radio value={false}>Неправильный</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Button
                        type="dashed"
                        onClick={() => removeOption(field.name)}
                      >
                        Удалить вариант
                      </Button>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        addOption({
                          isCorrect: false,
                          text: ''
                        })
                      }
                      block
                    >
                      <PlusOutlined /> Добавить вариант
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Button
              type="dashed"
              onClick={() => removeQuestion(question.name)}
              style={{ marginTop: '10px' }}
            >
              Удалить вопрос
            </Button>
          </div>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => addQuestion({ type: 'answerQuestion' })}
            block
          >
            <PlusOutlined /> Добавить вопрос
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
)

export default QonzhyqStoriesVariants
