import { ASSESSMENT_T } from '../enum'
import { chooseCorrectPictureTable } from './ChooseCorrectPicture'
import { chooseCorrectTranslationTable } from './ChooseCorrectTranslation'
import { chooseCorrectAnswerTable } from './ChooseCorrectAnswer'
import { fillBlankSpaceTable } from './FillBlankSpace'
import { listenAudioFinishSentenceTable } from './ListenAudioFinishSentence'
import { listenAudioCreateSentenceTable } from './ListenAudioCreateSentence'
import { matchWordsTable } from './MatchWords'
import { translateSentenceTable } from './TranslateSentence'
import { whatDidYouHearTable } from './WhatDidYouHear'
import { answerQuestionTable } from './AnswerQuestion'
import { answerQuestionImageTable } from './AnswerQuestionImage'
import { qonzhyqStoriesTable } from './QonzhyqStories'

export const ASSESSMENT_TABLES = Object.freeze({
  [ASSESSMENT_T.chooseCorrectTranslation]: chooseCorrectTranslationTable,
  [ASSESSMENT_T.chooseCorrectAnswer]: chooseCorrectAnswerTable,
  [ASSESSMENT_T.chooseCorrectPicture]: chooseCorrectPictureTable,
  [ASSESSMENT_T.whatDidYouHear]: whatDidYouHearTable,
  [ASSESSMENT_T.matchWords]: matchWordsTable,
  [ASSESSMENT_T.translateSentence]: translateSentenceTable,
  [ASSESSMENT_T.listenAudioCreateSentence]: listenAudioCreateSentenceTable,
  [ASSESSMENT_T.listenAudioFinishSentence]: listenAudioFinishSentenceTable,
  [ASSESSMENT_T.fillBlankSpace]: fillBlankSpaceTable,
  [ASSESSMENT_T.answerQuestion]: answerQuestionTable,
  [ASSESSMENT_T.answerQuestionImage]: answerQuestionImageTable,
  [ASSESSMENT_T.qonzhyqStories]: qonzhyqStoriesTable
})
