import React from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import 'typeface-roboto'

import './App.css'
import Login from './auth/Login'
// import PublicPage from './PublicPage'
import Users from './users/Users.container'
import Notifications from './notifications/Notifications.container'
import Story from './stories/Stories.container'
import VideoPlaylist from './cartoons/Cartoons.container'
import Songs from './songs/ContentAudios.container'
import Tales from './tales/ContentAudios.container'
import Playlists from './playlists/Playlists.container'
import PlaylistsCollections from './playlistsCollections/PlaylistsCollections.container'
import Collections from './collections/Collections.container'
import Creators from './creators/Creator.container'
import Publishers from './publishers/Publishers.container'
import Tags from './tags/Tags.container'
import Genres from './genres/Genres.container'
import Categories from './categories/Categories.container'
import Instruments from './instruments/Instruments.container'
import Letters from './letters/Letters.container'
import Digits from './digits/Digits.container'
import Colors from './colors/Colors.container'
import Animals from './animals/Animals.container'
import Kitchen from './kitchens/Kitchens.container'
import QaraSozs from './qaraSozs/QaraSozs.container'
import Dashboard from './dashboard/Dashboard.container'
import EducationTemp from './educationTemp/EducationTemp.container'
import EducationElements from './educationSection/educationElements/EducationElements.container'
import PracticeLetters from './educationSection/practiceLetters/PracticeLetters.container'
import PracticeNumbers from './educationSection/practiceNumbers/PracticeNumber.container'
import PracticeColors from './educationSection/practiceColors/PracticeColor.container'
import PracticeItemsTwoColor from './educationSection/practiceItemsTwoColors/PracticeItemsTwoColors.container'
import PracticeFigures from './educationSection/practiceFigures/PracticeFigure.container'
import Topics from './educationSection/topics/Topics.container'
import EducationCollections from './educationSection/educationCollections/EducationCollections.container'
import Courses from './educationSection/courses/Courses.container'
import Lessons from './educationSection/lessons/Lessons.container'
import LessonTypeAudio from './educationSection/lessonTypeAudios/LessonTypeAudio.container'
import withHelmet from '../hocs/withHelmet'
import { ProvideAuth } from '../context/useAuth'
import { ProvideLoading } from '../context/useLoading'
import LoadingDialog from '../context/LoadingDialog'
import PrivateRoute from '../hocs/PrivateRoute'
import Syllables from './syllables/Syllables.container'
import Words from './words/Words.container'
import DeleteAccount from './users/DeleteUser'
import Banners from './banners/Banners.container'
import UserLevels from './userLevels/UserLevels.container'
import TraditionsArticles from './sandyq/traditionsArticles/TraditionsArticles.container'
import Traditions from './sandyq/traditions/Traditions.container'
import TraditionsCollections from './sandyq/traditionsCollections/TraditionsCollections.container'
import BackgroundImages from './sandyq/backgroundImages/BackgroundImages.container'
import Proverbs from './sandyq/proverbs/Proverbs.container'
import ProverbsCollections from './sandyq/proverbsCollections/ProverbsCollections.container'
import Transactions from './transactions/Transactions.container'
import UsersTransactions from './transactions/UsersTransactions.container'
import AppDetails from './transactions/AppDetails.container'
import Riddles from './sandyq/riddles/Riddles.container'
import RiddlesCollections from './sandyq/riddlesCollections/RiddlesCollections.container'
import Zhanyltpashtar from './sandyq/zhanyltpashtar/Zhanyltpashtar.container'
import Assessments from './kazlingo/assessments/Assessments.container'
import Modules from './kazlingo/roadmap/Modules.container'
import Sections from './kazlingo/roadmap/sections/Sections.container'
import Buttons from './kazlingo/roadmap/sections/buttons/Buttons.container'
import WordsContainer from './kazlingo/commonWords/CommonWords.container'
import AssessmentsContainer1 from './kazlingo/roadmap/sections/buttons/assessments/Assessments.container'
import AlphabetContainer from './kazlingo/alphabet/Alphabet.container'
import QShopContainer from './qhop/QShop.container'
import StreaksContainer from './streaks/Streaks.container'

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  headers: {
    isUpdate: true,
    authorization: localStorage.getItem('token')
      ? `Bearer ${localStorage.getItem('token')}`
      : null
  }
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  timeout: 100000,
  link: uploadLink
})

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ProvideAuth>
        <ProvideLoading>
          <BrowserRouter>
            <ToastContainer />
            <LoadingDialog />
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/delete-account" component={DeleteAccount} />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/delete-account"
              component={DeleteAccount}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/users"
              component={Users}
              roles={['admin']}
            />
            <PrivateRoute
              exact
              path="/notifications"
              component={Notifications}
              roles={['admin']}
            />
            <PrivateRoute
              exact
              path="/stories"
              component={Story}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/cartoons"
              component={VideoPlaylist}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/songs"
              component={Songs}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/tales"
              component={Tales}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/collections"
              component={Collections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/playlists"
              component={Playlists}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/playlistsCollections"
              component={PlaylistsCollections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/creators"
              component={Creators}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/publishers"
              component={Publishers}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/tags"
              component={Tags}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/genres"
              component={Genres}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/categories"
              component={Categories}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/instruments"
              component={Instruments}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/letters"
              component={Letters}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/topics"
              component={Topics}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/topics/:id/elements"
              component={EducationElements}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/educationCollections"
              component={EducationCollections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/educationCollections/:collectionId/courses"
              component={Courses}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/educationCollections/:collectionId/courses/:courseId/lessons"
              component={Lessons}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/traditionsArticles"
              component={TraditionsArticles}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/traditions"
              component={Traditions}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/traditionsCollections"
              component={TraditionsCollections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/backgroundImages"
              component={BackgroundImages}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/Proverbs"
              component={Proverbs}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/ProverbsCollections"
              component={ProverbsCollections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/lessonTypeAudios"
              component={LessonTypeAudio}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/digits"
              component={Digits}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/colors"
              component={Colors}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/animals"
              component={Animals}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/banners"
              component={Banners}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/transactions"
              component={Transactions}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/usersTransactions"
              component={UsersTransactions}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/appDetails"
              component={AppDetails}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kitchen"
              component={Kitchen}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/qaraSozder"
              component={QaraSozs}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              component={Dashboard}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/education"
              component={EducationTemp}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/practiceLetters"
              component={PracticeLetters}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/practiceNumbers"
              component={PracticeNumbers}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/practiceColors"
              component={PracticeColors}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/practiceItemsTwoColors"
              component={PracticeItemsTwoColor}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/practiceFigures"
              component={PracticeFigures}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/syllables"
              component={Syllables}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/words"
              component={Words}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/userLevel/:userId"
              component={UserLevels}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/riddles"
              component={Riddles}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/riddlesCollections"
              component={RiddlesCollections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/zhanyltpashtar"
              component={Zhanyltpashtar}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/assessments"
              component={Assessments}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/modules"
              component={Modules}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/modules/:moduleId/sections"
              component={Sections}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/modules/:moduleId/sections/:sectionId/buttons"
              component={Buttons}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/modules/:moduleId/sections/:sectionId/buttons/:buttonId/assessments"
              component={AssessmentsContainer1}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/commonWords"
              component={WordsContainer}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/kazlingo-admin/alphabet"
              component={AlphabetContainer}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/qshop"
              component={QShopContainer}
              roles={['admin', 'contentManager']}
            />
            <PrivateRoute
              exact
              path="/streaks"
              component={StreaksContainer}
              roles={['admin', 'contentManager']}
            />
          </BrowserRouter>
        </ProvideLoading>
      </ProvideAuth>
    </ApolloProvider>
  )
}

const EnhancedApp = withHelmet([
  { tag: 'title', content: 'Admin | Qonzhyq-app' }
])(App)

export default EnhancedApp
