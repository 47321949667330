import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WordsForm from './CommonWordsForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Words = ({ words, createHandler, updateHandler, deleteHandler }) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [wordsItem, setWordsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchItem, setSearchItem] = useState(null)
  const [listWords, setListWords] = useState(null)

  useEffect(() => {
    if (searchItem) {
      const filteredWords = words.filter(word => {
        return word.text?.toLowerCase().includes(searchItem.toLowerCase())
      })
      setListWords(filteredWords)
    } else {
      console.log('🚀 ~ useEffect ~ words:', words)

      setListWords(words)
    }
  }, [words, searchItem])

  const columns = [
    { title: 'Фраза/текст', dataIndex: 'text', width: '20%' },
    { title: 'Перевод', dataIndex: 'translation', width: '20%' },
    { title: 'Модуль', dataIndex: 'moduleName', width: '20%' },
    { title: 'Раздел', dataIndex: 'sectionName', width: '20%' },
    { title: 'Группа', dataIndex: 'group', width: '20%' },
    {
      title: 'Изображение',
      width: '5%',
      render: item => {
        return (
          <>
            <Image
              shape="square"
              style={{ width: 64, height: 64 }}
              src={item?.imageUrl}
            />
          </>
        )
      }
    },
    {
      title: 'Аудио',
      width: '5%',
      render: item => {
        let audioLink = item.audioUrl
        if (audioLink && !audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return audioLink ? (
          <audio controls="controls">
            {audioLink && <source src={audioLink} type="audio/mpeg" />}
          </audio>
        ) : (
          <></>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setWordsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setWordsItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listWords}
        columns={columns}
        rowKey={item => item._id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск фразы/текста"
              onChange={e => {
                setSearchItem(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая фраза
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая фраза"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <WordsForm form={form} />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
          }}
          onOk={() => {
            updateHandler(wordsItem._id, formForEdit, setEditModal)
          }}
        >
          <WordsForm form={formForEdit} initialValues={wordsItem} />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(wordsItem._id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {wordsItem.text} ?</h3>
        </Modal>
      )}
    </>
  )
}

Words.propTypes = {
  words: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Words
