import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../../../../context/useLoading'
import WithMainLayout from '../../../../../hocs/withMainLayout'
import Loading from '../../../../shared/Loading'
import { axiosKazlingoJson } from '../../../../../utils/axiosKazlingo'
import Buttons from './Buttons'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const ButtonsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  // const location = useLocation()
  const { moduleId, sectionId } = useParams()
  // const { buttons = null } = location.state || {}
  const [allButtons, setAllButtons] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchButtons = async () => {
      try {
        const { data } = await axiosKazlingoJson.get(
          `/modules/${moduleId}/sections/${sectionId}/buttons`
        )
        setAllButtons(data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    // if (!buttons) {
    fetchButtons()
    // } else {
    //   console.log(buttons)
    //   setAllButtons(buttons)
    //   setLoading(false)
    // }
  }, [])

  const addButtonHandler = async (form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const response = await axiosKazlingoJson.post(
        `/modules/${moduleId}/sections/${sectionId}/buttons`,
        values
      )
      if (response.status == 200) {
        setAllButtons([...allButtons, values])
        toast.success('Успешно добавлено')
      }
    } catch (error) {
      console.error('Ошибка при добавлении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const updateButtonHandler = async (_id, form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const response = await axiosKazlingoJson.put(
        `/modules/${moduleId}/sections/${sectionId}/buttons/${_id}`,
        values
      )
      if (response.status == 200) {
        setAllButtons(allButtons.map(a => (a._id === _id ? values : a)))
        toast.success('Успешно обновлено')
      }
    } catch (error) {
      console.error('Ошибка при обновлении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteButtonHandler = async (_id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoJson.delete(
        `/modules/${moduleId}/sections/${sectionId}/buttons/${_id}`
      )
      setAllButtons(allButtons.filter(a => a._id !== _id))
      toast.success('Успешно удалено')
    } catch (error) {
      console.error('Ошибка при удалении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при удалении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке</div>
  }

  return (
    <Buttons
      buttons={allButtons}
      createHandler={addButtonHandler}
      updateHandler={updateButtonHandler}
      deleteHandler={deleteButtonHandler}
    />
  )
}

export default WithMainLayout(ButtonsContainer)
