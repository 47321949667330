import React from 'react'
import { Form, Input, Button, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const ListenAudioFinishSentenceVariants = () => (
  <Form.List name="variants" initialValue={[]}>
    {(fields, { add, remove }) => (
      <>
        {fields.map((field, index) => (
          <Form.Item key={field.key} label={`Вариант ${index + 1}`} required>
            <Form.Item
              name={[field.name, 'text']}
              rules={[{ required: true, message: 'Пожалуйста, введите текст' }]}
            >
              <Input placeholder="Введите текст" />
            </Form.Item>
            <Form.Item
              name={[field.name, 'correctIndex']}
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, выберите правильный индекс'
                }
              ]}
              getValueFromEvent={e => Number(e.target.value)}
            >
              <Input type="number" placeholder="Введите правильный индекс" />
            </Form.Item>
            <Button
              type="dashed"
              onClick={() => remove(field.name)}
              style={{ marginTop: '10px' }}
            >
              Удалить вариант
            </Button>
          </Form.Item>
        ))}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button type="dashed" onClick={() => add()}>
            <PlusOutlined /> Добавить вариант
          </Button>
        </div>
      </>
    )}
  </Form.List>
)

export default ListenAudioFinishSentenceVariants
