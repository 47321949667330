import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import AssessmentsForm from './AssessmentsForm'
import { ASSESSMENT_TABLES } from '../../../../assessments/tables'
import UpIcon from '../../../../../../assets/icons/Up.svg'
import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min'
import AssignAssessmentForm from './AssignAssessmentForm'
import CommonWordsSearch from '../../../../commonWords/CommonWordsSearch'
import { axiosKazlingoJson } from '../../../../../../utils/axiosKazlingo'
import { ASSESSMENT_T_LC } from '../../../../assessments/enum'
import { handleTableChange } from '../../Sections'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const Assessments = ({
  allAssessmentsGroup,
  unAssignAssessmentHandler,
  createAssessmentHandler,
  assignAssessmentHandler,
  editAssessmentHandler,
  deleteGroupHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [formForAssign] = Form.useForm()
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const [assignModalVisible, setAssignModalVisible] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [assessmentsGroupIndex, setAssessmentsGroupIndex] = useState(null)
  const [assessmentsItem, setAssessmentsItem] = useState(null)
  const [list, setList] = useState(allAssessmentsGroup)
  const [assessmentsCount, setAssessmentsCount] = useState(null)
  const history = useHistory()
  const { moduleId, sectionId, buttonId } = useParams()
  const groupColumns = [
    {
      title: 'Часть кнопки',
      render: (item, record, index) => <div>{index}</div>,
      width: '20%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (item, record, index) => (
        <>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setAssessmentsGroupIndex(index)
            }}
          >
            Удалить
          </Button>
          <div>
            <Link to={`../../buttons`}>
              <Button style={{ marginLeft: '5%' }} onClick={handleTableChange}>
                <Icon src={UpIcon} style={{ transform: 'rotate(-90deg)' }} />
              </Button>
            </Link>
            <Button
              onClick={() => {
                setAssessmentsGroupIndex(index)
                handleTableChange()
              }}
              style={{ marginLeft: '5%' }}
            >
              <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
            </Button>
          </div>
        </>
      )
    }
  ]
  const groupItemsColumns = [
    {
      title: 'Порядок',
      dataIndex: 'assessmentOrder',
      width: '20%'
    },
    {
      title: 'Ассессмент',
      render: (item, record, index) => {
        if (!item.assessment) {
          console.log(item, ' is null')
          return <></>
        }
        const table = ASSESSMENT_TABLES[item.assessment.type]
        const horizontal = item.assessment.type === 'matchWords'
        return (
          <div key={index} style={horizontal ? { display: 'flex' } : {}}>
            <div>
              <strong>Тип: </strong>
              {ASSESSMENT_T_LC[item.assessment.type]}
            </div>
            {table?.map((column, index) => (
              <div
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <strong>{column.title}: </strong>
                {column.dataIndex ? (
                  <span>{item.assessment[column.dataIndex]}</span>
                ) : (
                  column.render && column.render(item.assessment)
                )}
              </div>
            ))}
          </div>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: (item, record, index) => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setAssessmentsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setAssessmentsItem(item)
            }}
          >
            Удалить
          </Button>
          <span>
            <Button
              onClick={() => {
                setAssessmentsGroupIndex(null)
                handleTableChange()
              }}
              style={{ marginLeft: '5%' }}
            >
              <Icon src={UpIcon} style={{ transform: 'rotate(-90deg)' }} />
            </Button>
          </span>
        </>
      )
    }
  ]
  const [columns, setColumns] = useState(groupColumns)

  useEffect(() => {
    if (assessmentsGroupIndex !== null) {
      setList(allAssessmentsGroup[assessmentsGroupIndex])
      setColumns(groupItemsColumns)
    } else {
      setList(allAssessmentsGroup)
      setColumns(groupColumns)
    }
    setAssessmentsCount(null)
  }, [assessmentsGroupIndex, allAssessmentsGroup])

  return (
    <>
      <Table
        onChange={handleTableChange}
        dataSource={list}
        columns={columns}
        rowKey="_id"
        locale={{
          emptyText: (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <h3>Нет данных для отображения, вернуться назад?</h3>
              <span>
                <Button
                  onClick={() => {
                    handleTableChange()
                    if (assessmentsGroupIndex) {
                      setAssessmentsGroupIndex(null)
                    } else {
                      history.push('../../buttons')
                    }
                  }}
                  style={{ marginLeft: '5%' }}
                >
                  <Icon src={UpIcon} style={{ transform: 'rotate(-90deg)' }} />
                </Button>
              </span>
            </div>
          )
        }}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setAssignModalVisible(true)}>
              <PlusOutlined /> Добавить ассессмент сюда
            </Button>
            <div>
              <Button
                type="primary"
                onClick={async () => {
                  if (assessmentsGroupIndex != null) {
                    setAssessmentsCount(
                      allAssessmentsGroup[assessmentsGroupIndex].length
                    )
                  } else {
                    const response = await axiosKazlingoJson.get(
                      `/modules/${moduleId}/sections/${sectionId}/buttons/${buttonId}/assessmentsCount`
                    )
                    setAssessmentsCount(response.data.count)
                  }
                }}
              >
                Количество ассессментов
              </Button>
              {assessmentsCount}
            </div>
            <Button type="primary" onClick={() => setCreateModalVisible(true)}>
              <PlusOutlined /> Создать ассессмент сюда
            </Button>
            <CommonWordsSearch
              assessmentsGroup={allAssessmentsGroup[assessmentsGroupIndex]}
              groupIndex={assessmentsGroupIndex}
              assessmentsItem={assessmentsItem}
            ></CommonWordsSearch>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={createModalVisible}
        title="Новый ассессмент"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setCreateModalVisible(false)
        }}
        onOk={() => {
          createAssessmentHandler(
            form,
            setAssessmentsItem,
            setCreateModalVisible,
            setAssignModalVisible
          )
        }}
      >
        <AssessmentsForm form={form} />
      </Modal>
      <Modal
        open={assignModalVisible}
        title="Добавить ассессмент"
        okText="Добавить"
        cancelText="Отмена"
        onCancel={() => {
          formForAssign.resetFields()
          setAssignModalVisible(false)
        }}
        onOk={() => {
          assignAssessmentHandler(formForAssign, setAssignModalVisible)
        }}
      >
        <AssignAssessmentForm
          form={formForAssign}
          assessmentsGroup={allAssessmentsGroup[assessmentsGroupIndex]}
          groupIndex={assessmentsGroupIndex}
          assessmentsItem={assessmentsItem}
        />
      </Modal>
      <Modal
        open={editModal}
        title="Изменение ассессмента"
        okText="Изменить"
        cancelText="Отмена"
        onCancel={() => {
          formForEdit.resetFields()
          setEditModal(false)
        }}
        onOk={() => {
          editAssessmentHandler(assessmentsItem, formForEdit, setEditModal)
        }}
      >
        <AssessmentsForm
          form={formForEdit}
          initialValues={assessmentsItem?.assessment}
        />
      </Modal>
      <Modal
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false)
        }}
        onOk={() => {
          if (assessmentsGroupIndex == null) return
          if (assessmentsItem !== null) {
            unAssignAssessmentHandler(
              assessmentsItem._id,
              assessmentsGroupIndex,
              setDeleteModal
            )
          } else {
            deleteGroupHandler(assessmentsGroupIndex, setDeleteModal)
          }
        }}
      >
        <h3>
          Вы действительно хотите удалить
          {assessmentsItem
            ? JSON.stringify(assessmentsItem)
            : assessmentsGroupIndex}
          ?
        </h3>
      </Modal>

      <CommonWordsSearch
        assessmentsGroup={allAssessmentsGroup[assessmentsGroupIndex]}
        groupIndex={assessmentsGroupIndex}
        assessmentsItem={assessmentsItem}
      ></CommonWordsSearch>
    </>
  )
}

Assessments.propTypes = {
  allAssessmentsGroup: PropTypes.arrayOf(PropTypes.array).isRequired,
  unAssignAssessmentHandler: PropTypes.func.isRequired,
  createAssessmentHandler: PropTypes.func.isRequired,
  assignAssessmentHandler: PropTypes.func.isRequired,
  editAssessmentHandler: PropTypes.func.isRequired,
  deleteGroupHandler: PropTypes.func.isRequired
}

export default Assessments
