import React from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { PlusOutlined, RetweetOutlined } from '@ant-design/icons'

const shuffleArray = array => {
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}

const MatchWordsVariantsForm = ({ form }) => {
  const addVariantsFromCorrectMatches = () => {
    const correctMatches = form.getFieldValue('correctMatches') || []

    correctMatches.forEach(match => {
      const [col1, col2] = match.split('~')
      form.setFieldsValue({
        column1: [...(form.getFieldValue('column1') || []), { text: col1 }],
        column2: [...(form.getFieldValue('column2') || []), { text: col2 }]
      })
    })
  }

  const randomizeColumns = () => {
    const column1 = form.getFieldValue('column1') || []
    const column2 = form.getFieldValue('column2') || []

    const shuffledColumn1 = shuffleArray(column1)
    const shuffledColumn2 = shuffleArray(column2)

    form.setFieldsValue({
      column1: shuffledColumn1,
      column2: shuffledColumn2
    })
  }

  const handleAdd = (add, index, fieldName) => {
    add()
    if (fieldName === 'column1') {
      form.setFieldsValue({
        column2: [...(form.getFieldValue('column2') || []), { text: '' }]
      })
    } else {
      form.setFieldsValue({
        column1: [...(form.getFieldValue('column1') || []), { text: '' }]
      })
    }
  }

  const handleRemove = (remove, index, fieldName) => {
    remove(index)
    const otherColumn = form.getFieldValue(fieldName)
    otherColumn.splice(index, 1)

    form.setFieldsValue({
      [fieldName]: otherColumn
    })
  }

  return (
    <>
      <Form.List name="correctMatches">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key} label={`Correct Match ${index + 1}`}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  rules={[{ required: true, message: 'Введите соответствие' }]}
                >
                  <Input placeholder="Введите правильное соответствие" />
                </Form.Item>
                <Button
                  type="dashed"
                  onClick={() => remove(field.name)}
                  style={{ marginBottom: '10px' }}
                >
                  Удалить
                </Button>
              </Form.Item>
            ))}
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%', marginBottom: '10px' }}
            >
              <PlusOutlined /> Добавить соответствие
            </Button>
          </>
        )}
      </Form.List>
      <Row gutter={16}>
        <Col span={12}>
          <Form.List name="column1">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={field.key}
                    label={`Колонка 1 - Вариант ${index + 1}`}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'text']}
                      rules={[{ required: true, message: 'Введите текст' }]}
                    >
                      <Input placeholder="Введите текст" />
                    </Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleRemove(remove, index, 'column2')}
                      style={{ marginBottom: '10px' }}
                    >
                      Удалить
                    </Button>
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={() => handleAdd(add, fields.length, 'column1')}
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <PlusOutlined /> Добавить вариант в колонку 1
                </Button>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={12}>
          <Form.List name="column2">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={field.key}
                    label={`Колонка 2 - Вариант ${index + 1}`}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'text']}
                      rules={[{ required: true, message: 'Введите текст' }]}
                    >
                      <Input placeholder="Введите текст" />
                    </Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleRemove(remove, index, 'column1')}
                      style={{ marginBottom: '10px' }}
                    >
                      Удалить
                    </Button>
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={() => handleAdd(add, fields.length, 'column2')}
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <PlusOutlined /> Добавить вариант в колонку 2
                </Button>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="dashed"
          onClick={addVariantsFromCorrectMatches}
          style={{ width: '100%', marginBottom: '10px' }}
        >
          Добавить варианты из correctMatches
        </Button>
        <Button
          type="dashed"
          onClick={randomizeColumns}
          style={{ width: '100%' }}
        >
          <RetweetOutlined /> Рандомизировать столбцы
        </Button>
      </Form.Item>
    </>
  )
}

export default MatchWordsVariantsForm
