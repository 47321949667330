import React from 'react'
import { Form, Input, Button, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const WhatDidYouHearVariants = () => {
  return (
    <Form.List name="variants" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item key={field.key} label={`Вариант ${index + 1}`} required>
              <Form.Item
                name={[field.name, 'text']}
                rules={[
                  { required: true, message: 'Пожалуйста, введите текст' }
                ]}
              >
                <Input placeholder="Введите текст" />
              </Form.Item>
              <Form.Item
                name={[field.name, 'isCorrect']}
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, выберите правильный вариант'
                  }
                ]}
                initialValue={false}
              >
                <Radio.Group>
                  <Radio value={true}>Правильный</Radio>
                  <Radio value={false}>Не правильный</Radio>
                </Radio.Group>
              </Form.Item>
              <Button
                type="dashed"
                onClick={() => remove(field.name)}
                style={{ marginTop: '10px' }}
              >
                Удалить вариант
              </Button>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add({ isCorrect: false })}
              style={{ width: '60%' }}
            >
              <PlusOutlined /> Добавить вариант
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default WhatDidYouHearVariants
