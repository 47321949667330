import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import {
  axiosKazlingoFormData,
  axiosKazlingoJson
} from '../../../utils/axiosKazlingo'
import Modules from './Modules'
const ModulesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allModules, setAllModules] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const { data } = await axiosKazlingoJson.get('/modules')
        setAllModules(data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    fetchModules()
  }, [])

  const addModuleHandler = async (form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      Object.keys(values).forEach(key => {
        formData.append(key, values[key])
      })
      const response = await axiosKazlingoFormData.post('/modules', formData)
      if (response.status == 201) {
        setAllModules([...allModules, response.data])
        toast.success('Модуль добавлен')
      }
    } catch (error) {
      console.error('Ошибка при добавлении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const updateModuleHandler = async (_id, form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      Object.keys(values).forEach(key => {
        formData.append(key, values[key])
      })
      const response = await axiosKazlingoFormData.put(
        `/modules/${_id}`,
        formData
      )
      setAllModules(allModules.map(a => (a._id === _id ? response.data : a)))
      toast.success('Модуль обновлен')
    } catch (error) {
      console.error('Ошибка при обновлении модуля:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteModuleHandler = async (_id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoJson.delete(`/modules/${_id}`)
      setAllModules(allModules.filter(a => a._id !== _id))
      toast.success('Модуль удален')
    } catch (error) {
      console.error('Ошибка при удалении модуля:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при удалении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке модулей</div>
  }

  return (
    <Modules
      modules={allModules}
      createHandler={addModuleHandler}
      updateHandler={updateModuleHandler}
      deleteHandler={deleteModuleHandler}
    />
  )
}

export default WithMainLayout(ModulesContainer)
