import React, { useState } from 'react'
import { Modal, Select, Input } from 'antd'
import axios from 'axios'
import { axiosKazlingoJson } from '../../../utils/axiosKazlingo'

export const ASSESSMENT_T = Object.freeze({
  // chooseCorrectPicture: 'chooseCorrectPicture',
  chooseCorrectTranslation: 'chooseCorrectTranslation',
  whatDidYouHear: 'whatDidYouHear'
  // matchWords: 'matchWords',
  // translateSentence: 'translateSentence',
  // listenAudioCreateSentence: 'listenAudioCreateSentence',
  // fillBlankSpace: 'fillBlankSpace'
})

export const ASSESSMENT_T_LC = Object.freeze({
  // chooseCorrectPicture: 'Выбери правильный рисунок',
  chooseCorrectTranslation: 'Выбери правильный перевод',
  whatDidYouHear: 'Что Вы услышали?'
  // matchWords: 'Соедини слова',
  // translateSentence: 'Переведи предложение',
  // listenAudioCreateSentence: 'Послушай и собери предложение',
  // fillBlankSpace: 'Заполни пробел'
})

export function CommonWordsSearch({ groupIndex: buttonGroup }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [textId, setTextId] = useState('')
  const [text, setText] = useState('')
  const [assessmentOrder, setAssessmentOrder] = useState(0)
  const [textList, setTextList] = useState([])
  const [stateType, setStateType] = useState(ASSESSMENT_T.chooseCorrectPicture)

  const searchCommonWord = async phrase => {
    try {
      const result = await axios.get(
        'https://admin-dev.ishold.kz/kazlingo/commonWords/search',
        {
          headers: {
            'Content-Type': 'application/json'
          },
          params: {
            keyWord: phrase
          }
        }
      )

      console.log('search result', result.data)

      return result.data
    } catch (error) {
      console.error('Error fetching common words:', error)
      throw error
    }
  }

  const handleInputChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const fetchedTextList = await searchCommonWord(searchQuery)

    setTextList(fetchedTextList)
  }

  const handleSelectTextId = selectedTextId => {
    setTextId(selectedTextId)
  }

  const handleSetAssessmentOrder = AssessmentOrder => {
    setAssessmentOrder(AssessmentOrder)
  }

  const handleSelectText = selectedText => {
    setText(selectedText)
  }

  const handleAssesmentCreate = async () => {
    try {
      // Create Assesment
      console.log('textId', textId)
      const response = await axios.post(
        `https://admin-dev.ishold.kz/kazlingo/assessments/${stateType}`,
        {
          commonWordId: textId
        }
      )

      const pageUrlWindow = CurrentPageUrlWindow()

      const { moduleId, sectionId, buttonId } = extractIdsFromUrl(
        pageUrlWindow.props.children[1]
      )

      // Assygn Assesment
      console.log('Assessment created', {
        data: response.data,
        stateType,
        buttonGroup,
        textId,
        assessmentId: response.data._id,
        assessmentOrder: assessmentOrder,
        assessmentGroup: buttonGroup,
        moduleId,
        sectionId,
        buttonId
      })

      await axiosKazlingoJson.post(
        `/roadmaps/${moduleId}/sections/${sectionId}/buttons/${buttonId}/assessments`,
        {
          assessmentId: response.data._id,
          assessmentOrder: assessmentOrder,
          assessmentGroup: buttonGroup
        }
      )

      setIsModalOpen(false) // Close the modal after successful creation
    } catch (error) {
      console.error('Error creating assessment:', error)
    }
  }

  function CurrentPageUrlWindow() {
    const currentUrl = window.location.href

    return <div>Current URL: {currentUrl}</div>
  }

  function extractIdsFromUrl(url) {
    const parsedUrl = new URL(url)
    const path = parsedUrl.pathname

    const regex = /modules\/([^/]+)\/sections\/([^/]+)\/buttons\/([^/]+)/
    const match = path.match(regex)

    if (match) {
      const [, moduleId, sectionId, buttonId] = match
      return { moduleId, sectionId, buttonId }
    } else {
      throw new Error(
        "URL format is incorrect or doesn't contain required parts."
      )
    }
  }

  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        style={{
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Создать ассесмент из базы слов
      </button>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleAssesmentCreate}
      >
        <div style={{ marginBottom: '20px' }}>
          <p>Выбор типа ассесмента</p>
          <Select
            placeholder="Выберите тип"
            mode="single"
            onSelect={value => {
              setStateType(value)
            }}
          >
            {Object.keys(ASSESSMENT_T).map(field => (
              <Select.Option
                key={ASSESSMENT_T[field]}
                value={ASSESSMENT_T[field]}
              >
                {ASSESSMENT_T_LC[field]}
              </Select.Option>
            ))}
          </Select>
        </div>
        <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Ввод"
            value={searchQuery}
            onChange={handleInputChange}
          />
          <button type="submit">Поиск</button>
        </form>
        <div>
          {textList.length > 0 ? (
            <ul>
              {textList.map((text, index) => (
                <li key={index}>
                  {text.text}{' '}
                  <button
                    onClick={() => {
                      handleSelectTextId(text._id)
                      handleSelectText(text.text)
                    }}
                  >
                    Выбрать
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p> </p>
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <p>Порядок упражнения </p>
          <Input
            type="number"
            placeholder="Порядок оценки"
            value={assessmentOrder}
            onChange={e => setAssessmentOrder(Number(e.target.value))}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <h4>Выбранный текст:</h4>
          <p>{text || 'текст не выбран!'}</p>
        </div>
      </Modal>
    </div>
  )
}

export default CommonWordsSearch
