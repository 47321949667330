import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WordsForm from './QShopForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const QShop = ({ goods, createHandler, updateHandler, deleteHandler }) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [goodsItem, setGoodsItem] = React.useState(null)

  const [goodsList, setGoodsList] = useState(null)

  useEffect(() => {
    setGoodsList(goods)
  }, goods)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: '20%'
    },
    {
      title: 'Картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image src={item.iconLink} />
          </>
        )
      }
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      key: 'category',
      width: '20%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          {/* <span>
              <Link to={`/genres/${item.id}`}>Редактировать</Link>
            </span> */}
          <span>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setGoodsItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={goodsList}
        columns={columns}
        rowKey={item => item._id}
        title={() => (
          <StyledHeaderContainer>
            {/* <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск буквы"
              onChange={e => setSearchItem(e.target.value.toLowerCase())}
            /> */}
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый товар
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый товар"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <WordsForm
          form={form}
          onSubmit={formData => createHandler(formData, setModalVisible)}
        />
      </Modal>

      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => setDeleteModal(false)}
          onOk={() => deleteHandler(goodsItem._id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {goodsItem.name}?</h3>
        </Modal>
      )}
    </>
  )
}

QShop.propTypes = {
  goods: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default QShop
