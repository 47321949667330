import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WordsForm from './AlphabetForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Letters = ({ letters, createHandler, updateHandler, deleteHandler }) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [wordsItem, setWordsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchItem, setSearchItem] = useState(null)
  const [listWords, setListWords] = useState(null)

  useEffect(() => {
    if (searchItem) {
      const filteredWords = letters.filter(word =>
        word.letter?.toLowerCase().includes(searchItem.toLowerCase())
      )
      setListWords(filteredWords)
    } else {
      setListWords(letters)
    }
  }, [letters, searchItem])

  const columns = [
    {
      title: 'Буква',
      dataIndex: 'letter',
      key: 'letter',
      width: '20%'
    },
    {
      title: 'Слово',
      dataIndex: 'text',
      key: 'text',
      width: '20%'
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.audioUrl
        if (audioLink && !audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return audioLink ? (
          <audio controls="controls">
            {audioLink && <source src={audioLink} type="audio/mpeg" />}
          </audio>
        ) : (
          <></>
        )
      }
    },
    {
      title: 'Варианты',
      render: item => (
        <div>
          {item.variants.map(variant => {
            let audioLink = variant.audioUrl
            if (audioLink && !audioLink.startsWith('https://')) {
              audioLink = 'https://' + audioLink
            }
            return (
              <div
                style={{
                  marginBottom: '10px',
                  backgroundColor: variant.isCorrect
                    ? 'rgba(173, 255, 47, 0.5)'
                    : 'rgba(255, 0, 0, 0.5)',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span>
                  <strong>Текст:</strong> {variant.text}
                </span>
                {audioLink ? (
                  <audio controls="controls">
                    <source src={audioLink} type="audio/mpeg" />
                  </audio>
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </div>
      )
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setWordsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setWordsItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listWords}
        columns={columns}
        rowKey={item => item._id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск буквы"
              onChange={e => setSearchItem(e.target.value.toLowerCase())}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая буква
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая буква"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <WordsForm
          form={form}
          onSubmit={formData => createHandler(formData, setModalVisible)}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => setEditModal(false)}
          onOk={() => updateHandler(wordsItem._id, formForEdit, setEditModal)}
        >
          <WordsForm form={formForEdit} initialValues={wordsItem} />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => setDeleteModal(false)}
          onOk={() => deleteHandler(wordsItem._id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {wordsItem.letter}?</h3>
        </Modal>
      )}
    </>
  )
}

Letters.propTypes = {
  letters: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Letters
