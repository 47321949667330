import React, { useEffect } from 'react'
import { Form, Input, Button, Radio, Upload } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'

const AlphabetForm = ({ form, initialValues }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        key="letter"
        label="Буква"
        name="letter"
        rules={[{ required: true, message: 'Пожалуйста, введите букву' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="text"
        label="Слово"
        rules={[{ required: true, message: 'Пожалуйста, введите слово' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="audioFile"
        label="Аудио файл"
        valuePropName="file"
        getValueFromEvent={e => e.file}
      >
        <Upload beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Выберите аудио файл</Button>
        </Upload>
      </Form.Item>
      <Form.List name="variants" initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                key={field.key}
                label={`Вариант ${index + 1}`}
                required
              >
                <Form.Item
                  name={[field.name, 'text']}
                  rules={[
                    { required: true, message: 'Пожалуйста, введите текст' }
                  ]}
                >
                  <Input placeholder="Введите текст" />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'isCorrect']}
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, выберите правильный вариант'
                    }
                  ]}
                  initialValue={false}
                >
                  <Radio.Group defaultValue={false}>
                    <Radio value={true}>Правильный</Radio>
                    <Radio value={false}>Не правильный</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name={[field.name, 'audioFile']}
                  valuePropName="file"
                  getValueFromEvent={e => e.file}
                >
                  <Upload beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Выберите аудио</Button>
                  </Upload>
                </Form.Item>
                <Button
                  type="dashed"
                  onClick={() => remove(field.name)}
                  style={{ marginTop: '10px' }}
                >
                  Удалить вариант
                </Button>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
              >
                <PlusOutlined /> Добавить вариант
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}

export default AlphabetForm