import React from 'react'
export const fillBlankSpaceTable = [
  {
    title: 'Название',
    dataIndex: 'correctText',
    width: '15%'
  },
  {
    title: 'Перевод',
    dataIndex: 'translateText',
    width: '15%'
  },
  {
    title: 'Варианты',
    render: item => (
      <div>
        {item.variants.map(variant => (
          <div
            style={{
              marginBottom: 10,
              borderWidth: 1,
              borderStyle: 'solid',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor:
                variant.correctIndex >= 0
                  ? 'rgba(173, 255, 47, 0.5)'
                  : 'rgba(255, 0, 0, 0.5)'
            }}
          >
            <span>
              <strong>Текст:</strong> {variant.text}
            </span>
            <span>
              <strong>Правильный индекс:</strong> {variant.correctIndex}
            </span>
          </div>
        ))}
      </div>
    )
  }
]
