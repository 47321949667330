import axios from 'axios'

// Экземпляр для работы с `multipart/form-data`
// TODO: change path by env
const axiosKazlingoFormData = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://admin.ishold.kz/kazlingo'
      : 'https://admin-dev.ishold.kz/kazlingo',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

axiosKazlingoFormData.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Экземпляр для работы с `application/json`
const axiosKazlingoJson = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://admin.ishold.kz/kazlingo'
      : 'https://admin-dev.ishold.kz/kazlingo',
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosKazlingoJson.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export { axiosKazlingoFormData, axiosKazlingoJson }
